/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  InputBase,
} from "@mui/material";
import Select from "react-select";
import AppsCard from "../Card";
import { Search } from "@mui/icons-material";
import "./navlanding.css";
import { useNavigate } from "react-router-dom";
import {
  getNavigatorApps,
  getUnitAssignedToUser,
} from "../../Services/landing.service";
import Loader from "../Loader";
import { APP, HOME, LOGIN } from "../../Constants/api.constant";
import { logGAPageView } from "../../utils/Helper";
import Header from "../LandingPage/Header";
import Sidebar from "../Sidebar/Sidebar";
import {
  localStorageGetItem,
  localStorageSetItem,
} from "../../../src/utils/Storage/storageUtils";
import refresh_button from "../../assets/images/refresh_button.svg";
import APP_CONST from "../../Constants/app.constant";
import { toastNotify } from "../../Container/toastServices.jsx";

const Navlanding = () => {
  logGAPageView(APP, "App Landing");

  const [space, setSpace] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [tab, setTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);
  const [categoryName, setCategoryName] = useState("All Apps");
  const [filteredGalleryList, setFilteredGalleryList] = useState([]);
  const [displayFilterList, setDisplayFilterList] = useState([]);
  const [selectUnitsOption, setselectUnitOption] = useState(
    JSON.parse(localStorageGetItem("selectedUnitData")) || []
  );
  const [langLabel, setLangLabel] = useState(
    JSON.parse(localStorage.getItem("selectedLanguage"))?.value || "en"
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const AppsColor = ["#F3F3F3"];

  const AppLinkColor = [
    "#FFFFFF",
    "#E8F6FF",
    "#FFFFFF",
    "#FEF1F1",
    "#F9F2F2",
    "#EBFFE9",
    "#FFFAE8",
    "#F9F2F2",
  ];

  if (tab !== 1) {
    navigate(HOME, {
      state: {
        tabValue: tab,
      },
    });
  }
  useEffect(() => {
    document
      .querySelector(".content-area-wrap")
      .classList.remove("landingPageBG", "darkBG", "transparentBG");
    document.querySelector(".content-area-wrap").classList.add("darkBG");
    document.title = "Canvas.ai - Gallery";
    setWindowHeight(window.innerHeight - 350);
    getUnits();
    getApps();
  }, []);

  const getWindowDimensions = () => {
    const { innerHeight: height } = window;
    return {
      height,
    };
  };

  useEffect(() => {
    const handleResize = () => {
      const { height } = getWindowDimensions();
      setWindowHeight(height - 350);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getApps = async () => {
    checkAppAccess();
    setIsLoading(true);

    getNavigatorApps()
      .then((res) => {
        if (Array.isArray(res.data)) {
          const result = res.data.map((item) => {
            return {
              ...item,
            };
          });
          setSearchQuery("");
          const coloredResponse = distributeColor(result);
          setSpace(coloredResponse);
          setSelectedNavApps(coloredResponse);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const getUnits = (userid) => {
    if (userid !== "") {
      return getUnitAssignedToUser(localStorageGetItem("userId"))
        .then((res) => {
          let options = [];
          for (let i = 0; i < res.data.length; i++) {
            options.push({
              value: res.data[i]._id,
              label: res.data[i].unitName,
            });
          }
          setselectUnitOption(
            localStorageGetItem("selectedUnitData") === null
              ? options
              : JSON.parse(localStorageGetItem("selectedUnitData"))
          );

          localStorageSetItem("unitdata", JSON.stringify(options) || []);
        })
        .catch((error) => {
          console.log(error);
          toastNotify(APP_CONST.SOMETHING_WENT_WRONG, "error");
        });
    }
  };

  useEffect(() => {
    setSelectedNavApps(space);
    setSearchQuery("");
  }, [categoryName]);

  useEffect(() => {
    setSelectedNavApps(space);
  }, [selectUnitsOption]);

  const getUnitFilterApps = (navApps) => {
    return navApps.filter((item) => {
      return (
        item.units !== null && // Ensure units is not null
        item.units.length > 0 &&
        selectUnitsOption.some((i) => item.units.includes(i.value)) // Include if units has values and matches selected options
      );
    });
  };

  const setSelectedNavApps = (navApps) => {
    const unitFilteredApps = getUnitFilterApps(navApps);
    const filteredNavApps =
      categoryName === "All Apps"
        ? unitFilteredApps
        : unitFilteredApps
            .sort((a, b) => a?.name?.localeCompare(b.name))
            .filter((item) => {
              return (
                item.name.includes(categoryName?.toLowerCase()) ||
                item?.tag.some((tag) =>
                  tag?.toLowerCase()?.includes(categoryName?.toLowerCase())
                ) ||
                item.appLabel.includes(categoryName?.toLowerCase())
              );
            });
    setFilteredGalleryList(filteredNavApps);
    setDisplayFilterList(filteredNavApps);
  };
  const matchesQuery = (obj, query) => {
    const lowerCaseQuery = query.toLowerCase();
    if (obj.name.toLowerCase().includes(lowerCaseQuery)) {
      return true;
    }
    if (
      obj.tag.some((tagItem) => tagItem.toLowerCase().includes(lowerCaseQuery))
    ) {
      return true;
    }
    if (obj.appLabel.toLowerCase().includes(lowerCaseQuery)) {
      return true;
    }
    return false;
  };

  const handleUnitSelect = (e) => {
    let val = e;
    if (Array.isArray(e)) {
      val = e.map((unitValue) => ({
        label: unitValue.label,
        value: unitValue.value,
      }));
    }
    localStorageSetItem("selectedUnitData", JSON.stringify(val) || []);
    setselectUnitOption(val);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query === "") {
      setDisplayFilterList(filteredGalleryList);
    } else {
      setDisplayFilterList(
        filteredGalleryList.filter((item) => matchesQuery(item, query))
      );
    }
  };

  const distributeColor = (arr) => {
    let arrData = arr;
    for (let i = 0; i < arrData.length; i++) {
      arrData[i].bg_color = AppsColor[0];
      arrData[i].link_color = AppLinkColor[i % 8];
    }
    return arrData;
  };

  const checkAppAccess = () => {
    const accessToken = localStorageGetItem("access_token");
    if (typeof accessToken === "undefined" && accessToken === null)
      navigate(LOGIN);
  };

  return (
    <>
      <Header
        tab={tab}
        setTab={setTab}
        langLabel={langLabel}
        setLangLabel={setLangLabel}
      />
      <div
        width={"100%"}
        className="text-center my-3">
        <Typography className="text-container">
          AI powered Navigator applications
        </Typography>
      </div>
      <div className="headerGrid d-flex">
        <div className="d-flex action-items">
          <div className="filter-main">
            <div className="unit-dropdown">
              <label className="filter-label-inside">Units:</label>
              <Select
                className={`unit-react-select-container ${langLabel}`}
                classNamePrefix="react-select"
                menuPlacement="bottom"
                closeMenuOnSelect={true}
                value={selectUnitsOption}
                name="unit"
                id="unit"
                placeholder="Select Unit"
                options={JSON.parse(localStorageGetItem("unitdata"))}
                onChange={(value) => handleUnitSelect(value)}
                isMulti
              />
            </div>
          </div>
          <div className="d-flex right-corner-parent justify-content-end">
            <InputBase
              className={`styledInputBase ${
                isMdDown ? "styledInputBaseAlignment" : "styledInputBaseAlign"
              }`}
              sx={{
                border: "1px solid #75747a", // Change border color
                color: "#ffff", // Change text color
                borderRadius: "25px",
                "&:focus-visible": {
                  border: "1px solid #c6e814",
                },
              }}
              isMdDown={isMdDown}
              startAdornment={
                <Search
                  sx={{ color: "#C6E814", margin: "0px 8px 0px 16px" }}
                  fontSize="1.8vh"
                />
              }
              value={searchQuery}
              placeholder="Search"
              onChange={handleSearchChange}
            />
            <button
              className="space-refresh px-2 ms-3 d-flex justify-content-center align-items-center"
              onClick={() => {
                getApps();
              }}>
              <img src={refresh_button} />
            </button>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
      <div className="galleryListContainer overflow-auto">
        <div className="sidebar-container">
          <Sidebar setCategoryName={setCategoryName} />
        </div>
        <Grid
          container
          direction="row"
          className="grid-cards p-0">
          <Grid
            container
            className={`gridContainer m-0 ${
              displayFilterList?.length === 0 ? "empty" : "p-0"
            } ${displayFilterList?.length > 0 ? "normal" : "center"}`}
            spacing={3}
            minHeight={displayFilterList?.length === 0 && windowHeight}
            justifyContent={
              displayFilterList?.length > 0 ? "flex-start" : "center"
            }
            alignItems={displayFilterList?.length > 0 ? "normal" : "center"}>
            <>
              {displayFilterList?.length > 0 ? (
                <>
                  {displayFilterList?.map((data, index) => (
                    <AppsCard
                      data={data}
                      index={index}
                      setDisplayFilterList={setDisplayFilterList}
                    />
                  ))}
                </>
              ) : isLoading ? (
                <></>
              ) : (
                <>
                  <div className="align-center justify-center flex-column text-center text-default">
                    <p className="empty-appList">There are no Navigator Apps</p>
                  </div>
                </>
              )}
              <div
                className={`copyRightSection d-flex justify-center ${
                  displayFilterList?.length > 4 ? "normal" : "bottom"
                }`}>
                © 2024 LTIMindtree Limited
              </div>
            </>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default Navlanding;
