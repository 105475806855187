const ja = {
  translation: {
    chat: {
      chatList: "チャットリスト",
      noChat: "以前のチャットログはありません.新しいチャット を作成する",
      newConv: {
        msg1: "Canvas.ai との新しい会話を開始しています",
        msg2: "新しいチャットを作成してチャットを開始しま.",
        msg3: "最初のメッセージを以下に入力します",
      },
      chatTitle: "チャットのタイトル.",
      searchChat: "チャットを検索",
      newChat: "新しいチャット",
      noSession: "この入力に関連するチャット セッションが見つかりません",
      sourceDocument: "ソースドキュメント",
      youCanAlsoAsk: "尋ねることもできます.",
      didYouMean: "ということでしたか？",
      myQuest: "いいえ、私の質問に対する回答だけを知りたいです.",
      from: "から",
      copyText: "テキストをコピーする",
      fileExport: "ファイルのエクスポート",
      chatForm: {
        newChat: "チャット名を入力してください",
        description: "説明",
        tone: "チャットトーンの選択",
        toneList: {
          tone0: "よりクリエイティブに",
          tone1: "より多くのバランス",
          tone2: "より正確に",
        },
        paramKey: "パラメータキーを入力してください",
        paramVal: "パラメータ値を入力してください",
        button: "作成する",
      },
      typeMessage: "ここにメッセージを入力してください",
      createChatSession: "チャットセッションを作成する",
      signOut: "サインアウト",
      enterChatName: "チャット名を入力してください",
      enterChatNameWs: "チャット名を先頭にスペースを入れずに入力してください",
      chatSesCreated: "チャットセッションが正常に作成されました",
      chatSesDeleted: "チャットセッションが削除されました",
      chatSesDeletErr:
        "内部エラーのため、チャット セッションの削除に失敗しました",
      chatDeltoolTip: "このチャット セッションを削除します",
      chatCletoolTip: "検索をクリア",
      unableToTrans: "内部エラーのため翻訳できません",
      spaceNotAlwd: "入力フィールドにスペースは使用できません",
      copyClip: "クリップボードにコピーされました",
      enterMsg: "メッセージを入力してください",
      onlySpace: "スペースまたは特殊文字のみは使用できません",
      wetWrong: "何か問題が発生しました",
      defaultErr: "重大なエラーです。サポートにお問い合わせください",
      chatTitleError: "チャットのタイトルはすでに存在します",
      inferenceError: "推論に誤りがあります！管理者に連絡してください",
      quotaError:
        "こんにちは , このアプリケーションの 1 時間の割り当て制限に達しました。次の 1 時間後にもう一度お試しください",
      respSource: "LTIM-標準-日本語-推論",
    },
  },
};
export default ja;
