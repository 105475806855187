const en = {
  translation: {
    chat: {
      chatList: "Chat List",
      noChat: "No previous chat log. Create New Chat ",
      newConv: {
        msg1: "You're starting a new conversation with Canvas.ai",
        msg2: "Create New Chat and start chatting.",
        msg3: "Create New Chat and start chatting.",
      },
      chatTitle: "Chat Title.",
      searchChat: "Search Chat",
      newChat: "New Chat",
      noSession: "Type your first message below.",
      sourceDocument: "Source Document(s)",
      youCanAlsoAsk: "You can also ask.",
      didYouMean: "Did you mean?",
      myQuest: "No, I would like to see the response of my question only.",
      from: "From",
      copyText: "Copy Text",
      fileExport: "File Export",
      chatForm: {
        newChat: "Enter Chat Name",
        description: "Description",
        tone: "Select Chat Tone",
        toneList: {
          tone0: "More Creative",
          tone1: "More Balance",
          tone2: "More Precise",
        },
        paramKey: "Enter Parameters Key",
        paramVal: "Enter Parameters Value",
        button: "Create",
      },
      typeMessage: "Enter your message here",
      createChatSession: "Create chat session",
      signOut: "Sign Out",
      enterChatName: "Please enter chat name",
      enterChatNameWs: "Please enter chat name without any leading spaces",
      chatSesCreated: "Chat session successfully created",
      chatSesDeleted: "Chat session deleted",
      chatSesDeletErr:
        "The deletion of the chat session was unsuccessful due to an internal error",
      chatDeltoolTip: "Delete this chat session",
      chatCletoolTip: "Clear search",
      unableToTrans: "Unable to translate due to an internal error",
      spaceNotAlwd: "Space not allowed in input field",
      copyClip: "Copied to clipboard",
      enterMsg: "Please enter your message",
      onlySpace: "Only spaces or special character is not allowed.",
      wetWrong: "Something went wrong",
      chatTitleError: "Chat Title is already exist",
      defaultErr: "Severe error, please contact support",
      inferenceError: "Error in inference! Please contact to Admin",
      quotaError:
        "Hello , you have reached your quota limit for the hour in this application. Please try again in the next hour",
      respSource: "LTIM-Standard-Japanese-Inference",
    },
  },
};
export default en;
