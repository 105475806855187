import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  InputBase,
} from "@mui/material";
import "./Resources.scss";
import Videos from "./Videos";
import { Search } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const Resources = () => {
  const [tabValue, setTabValue] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [sortingType, setSortingType] = useState("relevance");
  const [isOpen, setIsOpen] = useState(false);

  const sortingRef = useRef(null);
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    document.title = "Canvas.ai - Resource";
    document
      .querySelector(".content-area-wrap")
      .classList.remove("landingPageBG", "darkBG", "transparentBG");
    document.querySelector(".content-area-wrap").classList.add("darkBG");
  }, []);

  const clickOutsideSort = (e) => {
    if (sortingRef.current && !sortingRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideSort);
    return () => {
      document.removeEventListener("mousedown", clickOutsideSort);
    };
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleSort = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (type) => {
    setSortingType(type);
  };

  return (
    <div className="resources-home">
      <div className="resource-tabs d-flex justify-content-end">
        <div className="search-sort-container d-flex justify-content-end align-items-center">
          <div className="d-flex justify-content-end search-bar">
            <InputBase
              className={`styledInputBase ${
                isMdDown ? "styledInputBaseAlignment" : "styledInputBaseAlign"
              }`}
              isMdDown={isMdDown}
              startAdornment={
                <Search
                  sx={{ color: "#C6E814", margin: "0px 8px 0px 16px" }}
                  fontSize="1.8vh"
                />
              }
              value={searchText}
              placeholder="Search"
              onChange={handleSearch}
            />
          </div>
          <div
            className="sort-by"
            onClick={handleSort}
            ref={sortingRef}>
            <span className="text">Sort by</span>
            <span className="down-arrow">
              {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </span>
          </div>
        </div>
      </div>
      <ul className={`dropdown-menu-head-resources ${isOpen ? "open" : ""}`}>
        <li
          onClick={() => {
            handleClick("relevance");
          }}>
          Relevance
        </li>
        <li onClick={() => handleClick("created")}>Created</li>
      </ul>
      {tabValue === 0 && (
        <Videos
          searchText={searchText}
          sortingType={sortingType}
        />
      )}
    </div>
  );
};

export default Resources;
