import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useState } from "react";
import "./HeaderDropdown.css";
import { Button, Menu } from "@mui/material";
import APP_CONST from "../../Constants/app.constant";
import createAppIcon from "../../assets/images/createAppIcon.svg";
import createStudioIcon from "../../assets/images/creatorStudioIcon.svg";
import { localStorageSetItem } from "../../utils/Storage/storageUtils";

const GotoDropDown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const navigateToStudio = (type) => {
    if (type === APP_CONST.CREATE_APP) {
      localStorageSetItem("user_persona", "Creator");
      localStorageSetItem("create_new_app", true);
      window.open("/studio/navigation-apps", "_blank");
    } else {
      window.open("/studio/home", "_blank");
    }
  };

  return (
    <div className="headerDropdown-goto me-2">
      <Button
        id="header-button-goto"
        aria-controls={open ? "header-menu-goto" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        className="button-goto"
      >
        {APP_CONST.GO_TO}
      </Button>
      <Menu
        id="header-menu-goto"
        MenuListProps={{
          "aria-labelledby": "header-button-goto",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        className="custom-menu-header"
      >
        <ul className="dropdown-menu-header">
          <li
            className="menu-opt d-flex align-center"
            onClick={() => {
              navigateToStudio(APP_CONST.CREATE_APP);
            }}
          >
            <div className="img-box">
              <img src={createAppIcon} alt="create-app" />
            </div>
            <div className="text-createApp">{APP_CONST.CREATE_APP}</div>
          </li>
          <li
            className="menu-opt d-flex align-center"
            onClick={() => navigateToStudio(APP_CONST.CREATOR_STUDIO)}
          >
            <div className="img-box">
              <img src={createStudioIcon} alt="create-app" />
            </div>
            <div className="text-createApp">{APP_CONST.CREATOR_STUDIO}</div>
          </li>
        </ul>
      </Menu>
    </div>
  );
};
export default GotoDropDown;
