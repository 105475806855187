import React, { useEffect, useState } from "react";
import { getAllNavigatorApps } from "../../Services/discover.service";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  InputBase,
} from "@mui/material";
import Loader from "../Loader";
import { Search } from "@mui/icons-material";
import "./Discovery.css";
import APP_CONST from "../../Constants/app.constant";

import DiscoveryTable from "./DiscoveryTable";

function Discovery() {
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableData, setTableData] = useState([]);

  const [paginationObject, setPaginationObject] = useState({
    hasMore: false,
    index: 0,
  });

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    fetchRecords();
    setSearchQuery("");
    document
      .querySelector(".content-area-wrap")
      .classList.remove("landingPageBG", "darkBG", "transparentBG");
    document.querySelector(".content-area-wrap").classList.add("darkBG");
    document.title = "Canvas.ai - Discover";
  }, []);

  useEffect(() => {
    if (paginationObject.hasMore) fetchRecords();
  }, [paginationObject]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };
  /*
    steps for [fetchRecords] function:
    1.call the first instance of api for limitted records
    2.based on the index value - fetch more records with updated index value
    3.fetch more records until we fetch all the records
  */
  const fetchRecords = async () => {
    if (paginationObject.index === null) return;
    if (paginationObject.index === 0) {
      setIsLoading(true);
    }
    await getAllNavigatorApps(paginationObject.index, APP_CONST.LIMIT_SIZE)
      .then((res) => {
        const response = res.data.data;
        if (isLoading) {
          setIsLoading(false);
        }
        if (res.data.pagination) {
          setPaginationObject({
            hasMore: res.data.pagination.hasNextPage,
            index: res.data.pagination.nextPageIndex,
          });
        }
        setTableData((prevRecords) => [...prevRecords, ...response]);
      })
      .catch((error) => {
        console.log("error", error);
        if (isLoading) {
          setIsLoading(false);
        }
      });
  };

  return (
    <>
      {isLoading && paginationObject?.index === 0 && <Loader />}
      <div className="discovery-main-container mt-4">
        <div className="discovery-title">
          <Box width={isMdDown ? "100%" : "55%"} className="text-center">
            <Typography className="text-container">
              Discover a suitable application for your needs from the below list
              of published apps
            </Typography>
          </Box>
        </div>
        <div className="discovery-searchbox d-flex justify-content-end">
          <div className="d-flex discovery-search justify-content-end">
            <InputBase
              className={`styledInputBase ${
                isMdDown ? "styledInputBaseAlignment" : "styledInputBaseAlign"
              }`}
              isMdDown={isMdDown}
              startAdornment={
                <Search
                  sx={{ color: "#C6E814", margin: "0px 8px 0px 16px" }}
                  fontSize="1.8vh"
                />
              }
              value={searchQuery}
              placeholder="Search"
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <DiscoveryTable
          tableData={tableData}
          searchQuery={searchQuery}
          paginationObject={paginationObject}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}

export default Discovery;
