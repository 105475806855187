const ar = {
  translation: {
    chat: {
      chatList: "قائمة الدردشة",
      noChat: "لا يوجد سجل دردشة سابق. إنشاء دردشة جديدة",
      newConv: {
        msg1: "أنت تبدأ محادثة جديدة باستخدام Canvas.ai",
        msg2: "أنشئ دردشة جديدة وابدأ الدردشة.",
        msg3: "قم بإنشاء دردشة جديدة وابدأ الدردشة.",
      },
      chatTitle: "عنوان الدردشة.",
      searchChat: "بحث الدردشة",
      newChat: "دردشة جديدة",
      noSession: "اكتب رسالتك الأولى أدناه.",
      sourceDocument: "المستند (المستندات) المصدر",
      youCanAlsoAsk: "يمكنك أيضا أن تسأل.",
      didYouMean: "هل تعني؟",
      myQuest: "لا، أود أن أرى الرد على سؤالي فقط.",
      from: "من",
      copyText: "نسخ النص",
      fileExport: "تصدير الملف",
      chatForm: {
        newChat: "أدخل اسم الدردشة",
        description: "وصف",
        tone: "حدد نغمة الدردشة",
        toneList: {
          tone0: "المزيد من الإبداع",
          tone1: "المزيد من التوازن",
          tone2: "أكثر دقة",
        },
        paramKey: "أدخل قيمة المعلمات",
        paramVal: "أدخل قيمة المعلمات",
        button: "يخلق",
      },
      typeMessage: "أدخل قيمة المعلمات",
      createChatSession: "إنشاء جلسة دردشة",
      signOut: "تسجيل الخروج",
      enterChatName: "الرجاء إدخال اسم الدردشة",
      enterChatNameWs: "الرجاء إدخال اسم الدردشة بدون أي مسافات بادئة",
      chatSesCreated: "تم إنشاء جلسة الدردشة بنجاح",
      chatSesDeleted: "تم حذف جلسة الدردشة",
      chatSesDeletErr: "لم ينجح حذف جلسة الدردشة بسبب خطأ داخلي",
      chatDeltoolTip: "احذف جلسة الدردشة هذه",
      chatCletoolTip: "مسح البحث",
      unableToTrans: "غير قادر على الترجمة بسبب خطأ داخلي",
      spaceNotAlwd: "المساحة غير مسموح بها في حقل الإدخال",
      copyClip: "تم النسخ إلى الحافظة",
      enterMsg: "الرجاء إدخال رسالتك",
      onlySpace: "غير مسموح بالمسافات أو الأحرف الخاصة فقط.",
      wetWrong: "حدث خطأ ما ",
      defaultErr: "خطأ فادح، يرجى الاتصال بالدعم",
      chatTitleError: "عنوان الدردشة موجود بالفعل",
      inferenceError: "خطأ في الاستدلال! يرجى الاتصال بالمسؤول",
      quotaError:
        "مرحبًا، لقد وصلت إلى الحد الأقصى لحصتك للساعة في هذا التطبيق. يرجى المحاولة مرة أخرى في الساعة القادمة",
      respSource: "",
    },
  },
};
export default ar;
