import { Grid, Typography, CardContent, Card } from "@mui/material";
import "./card.css";
import GalleryIcon1 from "../../assets/images/GalleryIcon1.svg";
import GalleryIcon2 from "../../assets/images/GalleryIcon2.svg";
import GalleryIcon3 from "../../assets/images/GalleryIcon3.svg";
import GalleryIcon4 from "../../assets/images/GalleryIcon4.svg";
import GalleryIcon5 from "../../assets/images/GalleryIcon5.svg";
import GalleryIcon6 from "../../assets/images/GalleryIcon6.svg";
import GalleryIcon7 from "../../assets/images/GalleryIcon7.svg";
import GalleryIcon8 from "../../assets/images/GalleryIcon8.svg";
import clapIconFilled from "../../assets/images/clap-icon-filled.svg";
import clapIconBlank from "../../assets/images/clap-icon-blank.svg";
import { useEffect, useState } from "react";
import AppModal from "../common/Modal/AppModal";
import { updateAppClap } from "../../Services/landing.service";
import APP_CONST from "../../Constants/app.constant";

const AppsCard = (props) => {
  const { data, index } = props;

  const [showAppModal, setShowAppModal] = useState(false);

  const randomColor = (colorIndex) => {
    switch (colorIndex) {
      case 0:
        return "colorCodeOne";
      case 1:
        return "colorCodeTwo";
      case 2:
        return "colorCodeThree";
      case 3:
        return "colorCodeFour";
      case 4:
        return "colorCodeFive";
      case 5:
        return "colorCodeSix";
      case 6:
        return "colorCodeSeven";
      case 7:
        return "colorCodeEight";
      default:
        break;
    }
  };

  const getGalleryIcon = (index) => {
    switch (index) {
      case 0:
        return GalleryIcon1;
      case 1:
        return GalleryIcon2;
      case 2:
        return GalleryIcon3;
      case 3:
        return GalleryIcon4;
      case 4:
        return GalleryIcon5;
      case 5:
        return GalleryIcon6;
      case 6:
        return GalleryIcon7;
      case 7:
        return GalleryIcon8;
      default:
        break;
    }
  };

  const toggleAddModal = (e) => {
    if (e?.target?.className?.includes(APP_CONST.CLAP_ICON_IMAGE)) {
      return;
    }
    setShowAppModal(true);
  };

  const updateClappedUserId = () => {
    const clappedIndex = data?.clappedBy?.findIndex(
      (ele) => ele === localStorage.getItem("authServerId")
    );
    if (clappedIndex !== -1) {
      data?.clappedBy?.splice(clappedIndex, 1);
    } else {
      data?.clappedBy?.push(localStorage.getItem("authServerId"));
    }
    return data.clappedBy;
  };

  const updateClap = () => {
    const payload = {
      userId: localStorage.getItem("authServerId"),
      appId: data._id,
    };
    updateAppClap(payload)
      .then((response) => {
        data.clapCount = response?.data?.clapCount;
        props.setDisplayFilterList((prevState) =>
          prevState.map((item) =>
            item._id === data._id
              ? {
                  ...item,
                  clapCount: response?.data?.clapCount,
                  clappedBy: updateClappedUserId(),
                }
              : item
          )
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const getClapIcon = () => {
    return data?.clappedBy?.includes(localStorage.getItem("authServerId"))
      ? clapIconFilled
      : clapIconBlank;
  };

  return (
    <>
      <Grid
        item
        xl={3}
        xs={12}
        md={6}
        className="card-container"
        onClick={(e) => toggleAddModal(e)}
      >
        <Card className={`styledCard ${randomColor(index % 8)}`}>
          <CardContent className="styledCardContent">
            <div className="apps-icon">
              <img src={getGalleryIcon(index % 8)} alt="" />
            </div>
            <Grid container className="appCardContainer" spacing={4}>
              <Grid item xs={12} className="p-0">
                <Grid container>
                  <Grid item xs={12}>
                    <div className="title-bar">
                      {data.appLabel?.length ? data.appLabel : data.name}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      fontFamily={"inter-regular"}
                      className="description-navapps"
                    >
                      {data.description}
                    </Typography>
                  </Grid>
                  <div className="clap-icon">
                    <img
                      className="clap-icon-image"
                      src={getClapIcon()}
                      onClick={() => updateClap()}
                    />
                    <span>{data.clapCount}</span>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {showAppModal && (
        <AppModal
          toggleAddModal={toggleAddModal}
          showAppModal={showAppModal}
          setShowAppModal={setShowAppModal}
          data={props}
          backgroundColor={randomColor(index % 8)}
          icon={getGalleryIcon(index % 8)}
          clapIcon={getClapIcon()}
          clapCount={data.clapCount}
          updateClap={updateClap}
        />
      )}
    </>
  );
};
export default AppsCard;
