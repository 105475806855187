import i18n from "i18next";
import en from "./lang/en";
import ja from "./lang/ja";
import ar from "./lang/ar";
import es from "./lang/es";
import fr from "./lang/fr";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources: { en, ja, ar, es, fr },
    // we init with resources
    fallbackLng: "en",
    debug: false,
    defaultNS: "translation",
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

export default i18n;
