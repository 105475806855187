import { Backdrop, CircularProgress } from "@mui/material";
import "./Loader.scss";

const Loader = () => {
  return (
    <Backdrop open className="backdrop-container">
      <CircularProgress
        thickness={8}
        sx={{
          color: "#c6e814",
        }}
      />
    </Backdrop>
  );
};
export default Loader;
