import React, { useEffect } from "react";
import aiChipIcon from "../../assets/images/ai-chip.svg";
import flexibleIcon from "../../assets/images/flexible.svg";
import intuitiveIcon from "../../assets/images/intuitive.svg";
import scalableIcon from "../../assets/images/scalable.svg";
import secureIcon from "../../assets/images/secure.svg";
import awsImg from "../../assets/images/aws.png";
import azureImg from "../../assets/images/azure.png";
import nvidiaImg from "../../assets/images/nvidia.svg";
import ibmImg from "../../assets/images/ibm.png";

import databaseImg from "../../assets/images/database-icon.svg";
import appImg from "../../assets/images/app-icon.svg";
import qualityImg from "../../assets/images/quality-icon.svg";
import scaleImg from "../../assets/images/scale-icon.svg";
import mailImg from "../../assets/images/mail-svgrepo-com.svg";
import { useNavigate } from "react-router-dom";
import {
  APP,
  CANVASAI_INTRO_VIDEO,
  VIDEO_SERVICE,
} from "../../Constants/api.constant";
import "./Home.css";
import { API_BASE_URL } from "../../Configuration/ServerConfig.js";
import powered from "../../assets/images/white-regular.png";
import CarouselComponent from "./CarouselComponent.jsx";
import bgVideo from "./../../assets/images/bgVideo.mp4";
import { removeTransparentBG } from "../../utils/Helper.js";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Canvas.ai";
    document.querySelector(".content-area-wrap").classList.remove("darkBG");
    document.querySelector(".content-area-wrap").classList.add("landingPageBG");

    const handleScroll = () => {
      const scrollableElement = document
        .querySelector(".authenticateContainer")
        .getBoundingClientRect();
      const blurValue = Math.min(Math.abs(scrollableElement.y) / 10, 100);
      document.querySelector(
        ".authenticateHeader"
      ).style.backdropFilter = `blur(${blurValue}px)`;
    };

    document
      .querySelector(".content-area-wrap")
      .addEventListener("scroll", handleScroll);

    document.querySelector(".content-area-wrap").scrollTo(0, 0);
    return () => {
      removeTransparentBG();
      document
        .querySelector(".content-area-wrap")
        .removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleVideoLoad = () => {
    document.querySelector(".content-area-wrap").classList.add("transparentBG");
  };

  return (
    <div className="authenticateContainer d-flex flex-column align-items-start">
      <video
        class="video-bg"
        muted
        loop
        autoPlay
        onLoadedData={handleVideoLoad}
      >
        <source src={bgVideo} type="video/mp4"></source>
      </video>
      <CarouselComponent />
      <div className="genaiFeaturesNewDiv w-100">
        <div className="genaiAppsNewMain">
          <div className="genaiAppsDesc">
            <span className="canvas-ai">Canvas.ai solves</span>
            <p className="canvas-ai-text-heading">
              for the unique challenges in an Application’s lifecycle
            </p>
          </div>
          <div className="wrapper-container">
            <div className="box-container">
              <div className="box">
                <div className="genai-content">
                  <img src={databaseImg} alt="databaseImg" />
                  <p>
                    Identify & Secure <br /> data for the solution
                  </p>
                </div>
              </div>
              <div className="second-section">
                <div>
                  <p className="canvasai-title">
                    <span className="canvasai-title-bold">Unstructured</span>,
                    multiple formats and above <br />
                    all <span className="canvasai-title-bold">scattered.</span>
                  </p>
                </div>
                <div className="genai-border"></div>
                <div>
                  <p className="canvasai-highligted">Content Hub</p>
                </div>
                <div className="genai-description">
                  <p>
                    to catalogue all the scattered artefacts, control access
                  </p>
                </div>
                <div className="genai-border"></div>
                <div>
                  <p className="canvasai-highligted">Data usage Insights</p>
                </div>
                <div className="genai-description">
                  <p>Data to use case lineages, consumption events</p>
                </div>
                <div className="genai-border"></div>
                <div>
                  <p className="canvasai-highligted">Access Control</p>
                </div>
                <div className="genai-description">
                  <p>
                    Full control over who and how the artefacts are used by AI
                    solutions
                  </p>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className="box-container">
              <div className="box">
                <div className="genai-content">
                  <img src={appImg} alt="appImg" />
                  <p>
                    Develop
                    <br /> Application
                  </p>
                </div>
              </div>
              <div className="second-section">
                <div>
                  <p className="canvasai-title">
                    New engineering technologies, Natural Language focused
                  </p>
                </div>
                <div className="genai-border"></div>
                <div>
                  <p className="canvasai-highligted">Units, Spaces</p>
                </div>
                <div className="genai-description">
                  <p>for tenancy, isolation</p>
                </div>
                <div className="genai-border"></div>
                <div>
                  <p className="canvasai-highligted">No-Code Pipelines</p>
                </div>
                <div className="genai-description">
                  <p>for fixed RAGs, advanced RAG, agentic flows</p>
                </div>
                <div className="genai-border"></div>
                <div>
                  <p className="canvasai-highligted">Single API</p>
                </div>
                <div className="genai-description">
                  <p>To all the Solutions, and LLMs behind them</p>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className="box-container">
              <div className="box">
                <div className="genai-content">
                  <img src={qualityImg} alt="qualityImg" />
                  <p>
                    Quality <br />
                    Assurance
                  </p>
                </div>
              </div>
              <div className="second-section">
                <div>
                  <p className="canvasai-title">
                    Emphasis on{" "}
                    <span className="canvasai-title-bold">Trust</span> <br />
                    on AI, ethical &{" "}
                    <span className="canvasai-title-bold">
                      responsible
                      <br />
                      AI
                    </span>{" "}
                    considerations
                  </p>
                </div>
                <div className="genai-border"></div>
                <div>
                  <p className="canvasai-highligted">LLM Response Evaluation</p>
                </div>
                <div className="genai-description">
                  <p>
                    test prompt-responses, identify drift, manage hallucinations
                  </p>
                </div>
                <div className="genai-border"></div>
                <div>
                  <p className="canvasai-highligted">Moderation</p>
                </div>
                <div className="genai-description">
                  <p>
                    for both prompts and responses; rule based and free form
                  </p>
                </div>
                <div className="genai-border"></div>
                <div>
                  <p className="canvasai-highligted">Lineage Tracker</p>
                </div>
                <div className="genai-description">
                  <p>cite the sources</p>
                </div>
              </div>
            </div>
            <div className="line"></div>
            <div className="box-container">
              <div className="box">
                <div className="genai-content">
                  <img src={scaleImg} alt="scaleImg" />
                  <p>
                    Democratize <br />& Scale
                  </p>
                </div>
              </div>
              <div className="second-section">
                <div>
                  <p className="canvasai-title">
                    New metrics for scaling:
                    <br />
                    Token throughput,
                    <br /> Token management.
                  </p>
                </div>
                <div className="genai-border"></div>
                <div className="kits-highlighted">
                  <p className="canvasai-highligted">Kits</p>
                </div>
                <div className="genai-description">
                  <p>ready to use UX kits (Search, Chat, Batch etc.)</p>
                </div>
                <div className="genai-border"></div>
                <div>
                  <p className="canvasai-highligted">App Gallery, Labs</p>
                </div>
                <div className="genai-description">
                  <p>entitlement based app galleries</p>
                </div>
                <div className="genai-border"></div>
                <div>
                  <p className="canvasai-highligted">AI Economics</p>
                </div>
                <div className="genai-description">
                  <p>budget, rate limit, reporting</p>
                </div>
                <div className="genai-border"></div>
                <div>
                  <p className="canvasai-highligted">Auditability</p>
                </div>
                <div className="genai-description">
                  <p>
                    full stack observability, metrics, events, logs and traces
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="canvasFeaturesDiv w-100 d-flex flex-column align-items-center">
        <div className="canvasAppsDesc">
          <span className="canvas-ai">Canvas.ai is</span>
        </div>
        <div className="row canvasFeaturesRow m-0">
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={flexibleIcon} />
                </span>
                <span className="canvasFeatureName">Flexible</span>
              </div>
              <span className="canvasFeatureDesc">
                The canvas.ai consumption layer offers extensive customization
                options to cater to a wide range of business needs and use case
              </span>
            </div>
          </div>
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={aiChipIcon} />
                </span>
                <span className="canvasFeatureName">Responsible AI</span>
              </div>
              <span className="canvasFeatureDesc">
                The security guard rails and proactive governance ensure
                ethical, transparent and compliant AI usage
              </span>
            </div>
          </div>
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={secureIcon} />
                </span>
                <span className="canvasFeatureName">Secure</span>
              </div>
              <span className="canvasFeatureDesc">
                The platform prioritizes data security and fair usage by
                budgeting coupons, rate limiting and reporting.
              </span>
            </div>
          </div>
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={intuitiveIcon} />
                </span>
                <span className="canvasFeatureName">Intuitive</span>
              </div>
              <span className="canvasFeatureDesc">
                An intuituve user interface enables ease-of-use in building and
                using solutions in a low-code, no-code environment
              </span>
            </div>
          </div>
          <div className="col-2 m-0">
            <div className="canvasFeatureDiv d-flex flex-column">
              <div className="canvasFeatureTitleDiv d-flex flex-row align-items-center">
                <span className="canvasFeatureIcon">
                  <img src={scalableIcon} />
                </span>
                <span className="canvasFeatureName">Scalable</span>
              </div>
              <span className="canvasFeatureDesc">
                Battle tested for speed-to-scale, the platorm promises quick
                response time for all queries
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="videoDiv d-flex justify-center">
        <video
          className="videoContainerHome"
          muted
          loop
          playsInline
          controls
          autoPlay
        >
          <source
            src={`${API_BASE_URL}${VIDEO_SERVICE}${CANVASAI_INTRO_VIDEO}`}
            type="video/mp4"
          ></source>
        </video>
      </div>
      <div className="navAppsDesc d-flex justify-center align-items-center flex-column">
        <div
          className="creator-apps-button"
          onClick={() => {
            navigate(APP);
          }}
        >
          <span>Go To Gallery</span>
        </div>
        <span className="navAppsDescSpan">
          For your AI powered navigator applications and copilots in one place,
          curated and neatly categorized.
        </span>
      </div>

      <div className="creatorAppsDesc d-flex justify-center align-items-center flex-column">
        <a
          className="creator-apps-button"
          href="/studio"
          onClick={() => {
            window.location.href = "/studio";
            window.location.reload(true);
          }}
        >
          <span>Go to Creator Studio</span>
        </a>
        <span className="creatorAppsDescSpan">
          Your next idea is few clicks away
        </span>
      </div>
      <div className="abstractBG">
        <div className="partnerSection d-flex flex-column">
          <div className="partnerSectionContent">
            <div className="partnerSectionHeader">
              Canvas.ai is powered by our partners
            </div>
            <div className="row m-0">
              <div className="col-1 m-0"></div>
              <div className="col-4 m-0 partnerSectionContentDiv">
                <img src={awsImg} className="awsImg" />
                <span className="partnerSectionContentDetails">
                  As early access partners for AWS’s Generative AI services,
                  Canvas.ai is built, hosted and entirely served on top of AWS
                </span>
              </div>
              <div className="col-1 m-0"></div>
              <div className="col-4 m-0 partnerSectionContentDiv">
                <img src={azureImg} className="azureImg" />
                <span className="partnerSectionContentDetails">
                  Most powerful models Azure OpenAI and Azure Cognitive services
                  are powering voice to text, and LLM usecases in Canvas.ai
                </span>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-1 m-0"></div>
              <div className="col-4 m-0 partnerSectionContentDiv">
                <img src={nvidiaImg} className="nvidiaImg" />
                <span className="partnerSectionContentDetails">
                  Nvidia’s GPUs and NIMS Inference Services are powering the LLM
                  models you are using in Canvas.ai. Nvidia Guardrails provides
                  the a freeform moderation for the incoming prompts and the
                  responses from LLMs
                </span>
              </div>
              <div className="col-1 m-0"></div>
              <div className="col-4 m-0 partnerSectionContentDiv">
                <img src={ibmImg} className="ibmImg" />
                <span className="partnerSectionContentDetails">
                  IBM’s WatsonX Governance is powering and amplifying
                  Canvas.ai’s LLM Response and Model testing components
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="partnerSectionFooter d-flex w-100">
        <div className="d-flex flex-column justify-center align-items-center contactUs">
          <span className="canvas-ai">Contact Us</span>
          <p className="mailSection m-0 d-flex">
            <img src={mailImg} alt="mailImg" />
            <a
              href="mailto:canvas.ai@ltimindtree.com"
              className="p-0 notranslate"
            >
              canvas.ai@ltimindtree.com
            </a>
          </p>
        </div>
      </div>
      <div className="copyRightSection d-flex justify-center w-100">
        © 2024 LTIMindtree Limited | Translation "
        <img src={powered} alt="powered" /> "
      </div>
    </div>
  );
};

export default Home;
