import Carousel from "react-bootstrap/Carousel";
import "./CarouselComponent.scss";
import bgBlend from "./../../assets/images/bgBlend.svg";

const carouselConfig = [
  {
    title: "Unlock the power of AI",
    description:
      "Swiftly transform your AI-driven ideas into production, with unmatched flexibility, accelerated development, and versatile cloud-native operations",
  },
  {
    title: "Agentic flows in just a few clicks!",
    description:
      "Create Agentic Flows to solve complex problems needing thoughts and reasoning. Tools Hub to organise and democratise Agent tools across the organization",
  },
  {
    title: "Regional AI with Language Packs",
    description:
      "Language Packages with Native Kits and Models now available for Arabic, Japanese, Spanish and French",
  },
];

const CarouselComponent = () => {
  const transitionHeading = (word, index) => {
    return (
      <span
        style={{
          animationDelay: `${index * 0.1 + 0.35}s`,
        }}
        className="mx-2"
      >
        {word}
      </span>
    );
  };

  return (
    <div className="w-100 carouselComponentContainer">
      <Carousel className="w-100 carouselComponent" pause={false}>
        {carouselConfig.map((carouselItem, index) => {
          return (
            <Carousel.Item interval={4000} key={index}>
              <div className="carouselContentDiv">
                <div className="canvasTitleDiv d-flex flex-column justify-center">
                  <h1 className="canvasTitle animated-text">
                    {carouselItem.title
                      .split(" ")
                      .map((word, index) => transitionHeading(word, index))}
                  </h1>
                  <h5 className="canvasDescription">
                    {carouselItem.description}
                  </h5>
                </div>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
      <img className="blendImg" src={bgBlend} alt="" />
    </div>
  );
};

export default CarouselComponent;
