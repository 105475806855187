// Login API
export const REAL_MS = "/realms";
export const CANVAS_AI = "/canvasai";
export const PROTOCOL = "/protocol";
export const OPENID_CONNECT = "/openid-connect";
export const TOKEN = "/token";
export const KEYCLOAK = "/keycloak";
export const CONTROL_PLANE_SERVICE = "/controlplaneservice";

// Landing Page API
export const LOGIN = "/login";
export const USER = "/user";
export const NAVIGATOR_APPS = "/navigatorapps";
export const CHAT_SERVICE = "/chatservice";
export const MARKED_FLAG = "/markedflag";
export const UNITS = "/units";

export const AUTH_USER_ID = "?authServerId=";

export const CLAP = "/clap";

// SSO
export const SSO_REALM = "canvasai";
export const SSO_CLIENT_ID = "canvasai-chat";

//Routes constants
export const HOME = "/";
export const APP = "/app";

//Videos
export const VIDEO_SERVICE = "/video-service";
export const VIDEOS = "/videos";
export const CANVASAI_INTRO_VIDEO = "/demovideo";
export const CHAT_ARABIC = "/chatarabic";

//Discovery
export const DISCOVERY = "/discover";
export const START_INDEX = "?startIndex=";
export const LIMIT = "&limit=";
