import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useEffect, useState } from "react";
import "./HeaderDropdown.css";
import { Button, Menu } from "@mui/material";

const LanguageDropdown = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    return savedLanguage
      ? JSON.parse(savedLanguage)
      : { label: "English", value: "en" };
  });
  const languages = [
    { label: "English", value: "en" },
    { label: "العربية", value: "ar" }, // Arabic
    { label: "Dutch", value: "nl" }, // Dutch
    { label: "Español", value: "es" }, // Spanish
    { label: "Français", value: "fr" }, // French
    { label: "German", value: "de" }, // German
    { label: "日本語", value: "ja" }, // Japanese
  ];

  const handleLanguageChange = (_event, index) => {
    const val = languages[index];
    const translateElement = document.querySelector(".goog-te-combo");
    if (translateElement) {
      translateElement.value = val.value;
      translateElement.dispatchEvent(new Event("change")); // Trigger translation
    }
    localStorage.setItem("selectedLanguage", JSON.stringify(val));
    setSelectedLanguage(val);
    handleCloseMenu();
    if(val.value !== props.langLabel){
      props.setLangLabel(val.value.toLowerCase())
    }
  };

  useEffect(() => {
    const translateElement = document.querySelector(".goog-te-combo");
    if (translateElement) {
      translateElement.value = selectedLanguage.value;
      translateElement.dispatchEvent(new Event("change"));
    }
  }, [selectedLanguage]);

  return (
    <div className="headerDropdown-language me-2">
      <Button
        id="header-button-lang"
        aria-controls={open ? "header-menu-lang" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        className="button-lang notranslate"
      >
        {selectedLanguage.label}
      </Button>
      <Menu
        id="header-menu-lang"
        MenuListProps={{
          "aria-labelledby": "header-button-lang",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        className="custom-menu-header"
      >
        <ul className="dropdown-menu-header language-menu">
          {languages?.map((language, index) => {
            return (
              <li
                key={index}
                onClick={(e) => handleLanguageChange(e, index)}
                className="notranslate"
              >
                {language.label}
              </li>
            );
          })}
        </ul>
      </Menu>
    </div>
  );
};
export default LanguageDropdown;
