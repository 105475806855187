import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { lazy, Suspense, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Components/Loader";
import { APP, HOME } from "./Constants/api.constant";
import LandingPage from "./Components/LandingPage/LandingPage";

const Navlanding = lazy(() => import("./Components/NavigationApp/navlanding"));

function App() {
  if (window?.IS_GA_ENABLED === "TRUE")
    ReactGA.initialize(window?.GA_MEASUREMENT_KEY);

  const [showLogo, setShowLogo] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowLogo(window.location.href.includes("/app"));
  }, [showLogo]);

  return (
    <div className={`main-container`}>
      <ToastContainer autoClose={500} pauseOnFocusLoss={false} />
      <div className={`content-area-wrap`}>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path={HOME}
              element={
                <LandingPage tabValue={location?.state?.tabValue || 0} />
              }
            />
            <Route path={APP} element={<Navlanding />} />
            <Route path="/*" element={<Navigate to={HOME} replace />} />
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default App;
