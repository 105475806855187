import { API } from "./base.service";
import {
  USER,
  NAVIGATOR_APPS,
  CHAT_SERVICE,
  DISCOVERY,
  START_INDEX,
  LIMIT,
} from "../Constants/api.constant";

export const getAllNavigatorApps = function (startIndex, limit) {
  return API.get(
    `${CHAT_SERVICE}${USER}${NAVIGATOR_APPS}${DISCOVERY}${START_INDEX}${startIndex}${LIMIT}${limit}`
  );
};
