import ReactGA from "react-ga4";
import { localStorageGetItem } from "./Storage/storageUtils";

export const isAdmin = () => {
  return localStorageGetItem("isAdmin") === "true";
};

export const logGAPageView = (page, title) => {
  if (window?.IS_GA_ENABLED === "TRUE") {
    ReactGA.send({
      hitType: "pageview",
      page: page,
      title: title,
    });
  }
};

export const removeTransparentBG = () => {
  document
    ?.querySelector(".content-area-wrap")
    ?.classList?.remove("transparentBG");
};
