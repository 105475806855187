import React from "react";
import "./VideoPopup.scss";
import crossIcon from "../../assets/images/closeIcon.svg";
import { VIDEO_SERVICE, VIDEOS } from "../../Constants/api.constant";
import { API_BASE_URL } from "../../Configuration/ServerConfig";

const VideoPopup = (props) => {
  const handleClose = () => {
    props.setShowVideoModal(!props.showVideoModal);
  };

  return (
    <div className="videoPopup">
      <div className="videoModalContainer">
        <div className="header-container">
          <div className="video-header">{props?.title}</div>
          <img src={crossIcon} alt="" onClick={handleClose} />
        </div>
        <div className="video-container">
          <video className="playVideo h-100 w-100" playsInline controls>
            <source
              src={`${API_BASE_URL}${VIDEO_SERVICE}${VIDEOS}/${props?.name}`}
              type="video/mp4"
            ></source>
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoPopup;
