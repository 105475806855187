const es = {
  translation: {
    chat: {
      chatList: "ListaChat",
      noChat: "No hay registro de chat anterior. Crear nuevo chat ",
      newConv: {
        msg1: "Estás iniciando una nueva conversación con Canvas.ai",
        msg2: "Crear Nuevo Chat y empezar a chatear",
        msg3: "Crear Nuevo Chat y empezar a chatear",
      },
      chatTitle: "Título del chat",
      searchChat: "Buscar Chat",
      newChat: "Nuevo Chat",
      noSession: "Escribe tu primer mensaje a continuación",
      sourceDocument: "Documento(s) de origen",
      youCanAlsoAsk: "También puedes preguntar",
      didYouMean: "¿Querías decir?",
      myQuest: "No, me gustaría ver sólo la respuesta a mi pregunta",
      from: "De",
      copyText: "Copiar Texto",
      fileExport: "Exportar archivo",
      chatForm: {
        newChat: "Introduzca el nombre del chat",
        description: "Descripción",
        tone: "Seleccionar tono de chat",
        toneList: {
          tone0: "Más creativo",
          tone1: "Más Equilibrado",
          tone2: "Más Preciso",
        },
        paramKey: "Introducir clave de parámetros",
        paramVal: "Introducir valor de parámetros",
        button: "Crear",
      },
      typeMessage: "Introduzca aquí su mensaje",
      createChatSession: "Crear sesión de chat",
      signOut: "Cerrar sesión",
      enterChatName: "Por favor, introduzca el nombre del chat",
      enterChatNameWs:
        "Por favor, introduzca el nombre del chat sin espacios a la izquierda",
      chatSesCreated: "Sesión de chat creada con éxito",
      chatSesDeleted: "Sesión de chat eliminada",
      chatSesDeletErr:
        "La eliminación de la sesión de chat no tuvo éxito debido a un error interno",
      chatDeltoolTip: "Borrar esta sesión de chat",
      chatCletoolTip: "Borrar búsqueda",
      unableToTrans: "No se ha podido traducir debido a un error interno",
      spaceNotAlwd: "Espacio no permitido en el campo de entrada",
      copyClip: "Copiado al portapapeles",
      enterMsg: "Por favor, introduzca su mensaje",
      onlySpace: "Sólo espacios o carácter especial no permitido",
      wetWrong: "Algo salió mal",
      chatTitleError: "El título del chat ya existe",
      defaultErr: "Error grave, por favor contacte con soporte",
      inferenceError:
        "¡Error en la inferencia! Por favor, póngase en contacto con Admin",
      quotaError:
        "Hola , ha alcanzado su límite de cuota para la hora en esta aplicación. Por favor, inténtalo de nuevo en la próxima hora",
      respSource: " ",
    },
  },
};
export default es;
