const fr = {
  translation: {
    chat: {
      chatList: "Liste de discussion",
      noChat: " Pas de journal de chat précédent. Créer un nouveau chat ",
      newConv: {
        msg1: " Vous commencez une nouvelle conversation avec Canvas.ai ",
        msg2: " Créez un nouveau chat et commencez à discuter ",
        msg3: " Create New Chat and start chatting. ",
      },
      chatTitle: " Titre du chat ",
      searchChat: "Chat de recherche ",
      newChat: " Nouveau chat ",
      noSession: " Tapez votre premier message ci-dessous ",
      sourceDocument: "Document source",
      youCanAlsoAsk: " Vous pouvez également demander ",
      didYouMean: " Avez-vous voulu dire ? ",
      myQuest: " Non, je voudrais seulement voir la réponse à ma question ",
      from: "De",
      copyText: " Copier le texte ",
      fileExport: " Exportation de fichier ",
      chatForm: {
        newChat: "Saisir le nom du chat",
        description: "Description",
        tone: "Sélectionnez la tonalité de discussion",
        toneList: {
          tone0: " Plus créatif ",
          tone1: " Plus équilibré ",
          tonalité2: " Plus précis ",
        },
        paramKey: " Entrez la clé des paramètres ",
        paramVal: " Entrez la valeur des paramètres ",
        bouton: " Créer ",
      },
      typeMessage: " Entrez votre message ici ",
      createChatSession: " Créer une session de chat ",
      signOut: " Se déconnecter ",
      enterChatName: " Veuillez saisir le nom du chat ",
      enterChatNameWs:
        " Veuillez entrer le nom du chat sans espace en début de phrase ",
      chatSesCreated: " Session de chat créée avec succès ",
      chatSesDeleted: " Session de chat supprimée ",
      chatSesDeletErr:
        " La suppression de la session de chat a échoué en raison d'une erreur interne ",
      chatDeltoolTip: " Supprimer cette session de chat ",
      chatCletoolTip: " Effacer la recherche ",
      unableToTrans: " Impossible de traduire en raison d'une erreur interne ",
      spaceNotAlwd: " Espace non autorisé dans le champ de saisie ",
      copyClip: " Copié dans le presse-papiers ",
      enterMsg: " Veuillez saisir votre message ",
      onlySpace:
        " Seuls les espaces ou les caractères spéciaux ne sont pas autorisés ",
      wetWrong: " Quelque chose s'est mal passé ",
      chatTitleError: " Le titre du chat existe déjà ",
      defaultErr: " Erreur grave, veuillez contacter l'assistance ",
      inferenceError:
        " Erreur dans l'inférence ! Veuillez contacter l'administrateur ",
      quotaError:
        " Bonjour , vous avez atteint votre limite de quota pour l'heure dans cette application. Veuillez réessayer dans la prochaine heure ",
      respSource: "  ",
    },
  },
};
export default fr;
