import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import clapIconFilled from "../../assets/images/clap-icon-filled-whitebordered.svg";
import "./DiscoveryTable.scss";

const columns = [
  { field: "appLabel", headerName: "Application", flex: 1, resizable: false },
  {
    field: "description",
    headerName: "Description",
    flex: 1,
    resizable: false,
  },
  {
    field: "userName",
    headerName: "Creator",
    flex: 1,
    resizable: false,
    cellClassName: "notranslate",
  },
  {
    field: "credits",
    headerName: "Credits",
    flex: 1,
    resizable: false,
    cellClassName: "notranslate",
  },
  { field: "tag", headerName: "Tags(s)", flex: 1, resizable: false },
  {
    field: "clapCount",
    headerName: <img src={clapIconFilled} alt="claps" className="clapImg" />,
    flex: 0.4,
    resizable: false,
  },
];

export default function DiscoveryTable(props) {
  const rows = props.tableData;
  const { searchQuery, paginationObject, isLoading } = props;

  const uniqueTags = (data) => {
    return [...new Set(data)];
  };

  const filterRows = (rowsData) => {
    const filteredRows = rowsData.filter((row) => {
      const values = Object.values(row).flat();
      return values?.some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    return filteredRows;
  };

  rows.forEach((row, index) => {
    row.id = index + 1;
    row.tag = uniqueTags(row.tag);
  });

  const tableRows = searchQuery.trim().length > 0 ? filterRows(rows) : rows;

  return (
    <>
      <div className="discovery-table">
        {!(paginationObject.index === 0 && isLoading) && (
          <>
            <DataGrid
              rows={tableRows}
              columns={columns}
              disableColumnMenu
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 20, 30]}
            />
          </>
        )}
      </div>
      <div
        className={`copyRightSection-discovery d-flex justify-center bottom ${
          paginationObject.index === 0 && isLoading
            ? "position-absolute bottom-0 start-0"
            : ""
        } `}
      >
        © 2024 LTIMindtree Limited
      </div>
    </>
  );
}
