import React, { useEffect, useState } from "react";
import "./Videos.scss";
import { getVideos } from "../../Services/videos.service";
import PlayVideo from "./PlayVideo";

const Videos = (props) => {
  const { searchText, sortingType } = props;
  const [videosData, setVideosData] = useState([]);
  useEffect(() => {
    // need to call videos API for getting details about the videos
    fetchVideos();
  }, [sortingType]);

  // fetch the videos
  const fetchVideos = () => {
    getVideos()
      .then((res) => {
        res?.data?.videos?.forEach((data) => {
          data.title = data?.Name?.replace(/[0-9_]|\.mp4/g, "");
          data.id = data?.Name?.substring(0, 2);
        });
        sortingType === "created"
          ? setVideosData(
              res?.data?.videos.sort(
                (a, b) => new Date(a.LastModified) - new Date(b.LastModified)
              )
            )
          : setVideosData(res?.data?.videos.sort((a, b) => a.id - b.id));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filteredVideos = () => {
    if (searchText === "") return videosData;
    const filteredList = videosData.filter((videoData) => {
      return videoData.title?.toLowerCase().includes(searchText.toLowerCase());
    });
    return filteredList || [];
  };

  return (
    <>
      <div className="videoContainer overflow-auto">
        {filteredVideos()?.map((item, index) => {
          return (
            <div
              className="videoModal"
              key={`video-${index}`}>
              <PlayVideo data={item} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Videos;
