import "./LandingPage.css";
import Logo from "../../assets/branding-image/aigallery_white.svg";
import UserAccount from "../UserAccount";
import APP_CONST from "../../Constants/app.constant";
import GotoDropDown from "./GotoDropDown";
import LanguageDropdown from "./LanguageDropdown";
import { removeTransparentBG } from "../../utils/Helper";

const Header = (props) => {
  const translations = {
    en: "Sign Out",
    ar: "تسجيل الخروج",
    nl: "Uitloggen",
    es: "Cerrar sesión",
    fr: "Se déconnecter",
    de: "Abmelden",
    ja: "サインアウト",
  };

  const updateTab = (index) => {
    if (index !== 0) {
      removeTransparentBG();
    }
    props.setTab(index);
  };

  return (
    <div className="authenticateHeader">
      <div className="logo-wrap">
        <img src={Logo} alt="canvas logo" />
      </div>

      <div className="tabs-section">
        <ul className="inner-tabs">
          {APP_CONST.TABS_HEADERS.map((tabValue, index) => {
            return (
              <li
                data-toggle="tab"
                key={`tabHeader-${index}`}
                className={`inner-tab-item${
                  props.tab === index ? " active" : ""
                }`}
                onClick={() => updateTab(index)}
              >
                {tabValue}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="headerRightSection">
        {window.IS_GT_ENABLED === "TRUE" && (
          <LanguageDropdown
            langLabel={props.langLabel}
            setLangLabel={props.setLangLabel}
          />
        )}
        <GotoDropDown />
        <div className="headerRightSection-icon">
          <UserAccount translations={translations} />
        </div>
      </div>
    </div>
  );
};

export default Header;
